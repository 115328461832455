import React from "react";
import { Layout } from "../../components";

const Marketing = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Marketing",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/marketing/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Marketing</h1>
          </div>
          <div className="single_offer__content">
            <div className="single_offer__marketing">
              <p className="text-center">
                google ads - google ad grants - kampanie zagraniczne - facebook
                - pr &amp; copywriting - audyty - szkolenia - promocja
                rekrutacji - bannery remarketingowe
              </p>
              <p>
                Tworzymy nowe marki i wprowadzamy je na rynek oraz pomagamy w
                refreshu i rebrandingu tych już istniejących. Opracowujemy
                nazwy, claimy i hasła reklamowe. Projektujemy logo i systemy
                identyfikacji wizualnej, opakowania i etykiety produktów dla
                wielu branż.
              </p>
              <h3>Dlaczego my?</h3>
              <p>
                Kilkanaście lat doświadczenia Przemyślany proces projektowy
                Bogate portfolio Klientów i projektów Doświadczenie w wielu
                branżach Kompetencje Digital i Print
              </p>
              <h3>Jak pracujemy?</h3>
              <div className="row boxes_row">
                <div className="col-lg-6 col_1">
                  <div className="inner_box">
                    <span>1.</span>
                    <h3>POZNAJEMY TWOJE CELE</h3>
                    <p>
                      Nasze strategie promocji szyte są na Twoją miarę. Aby
                      dopasować rozwiązania dla Twojej firmy musimy się poznać.
                      Preferujemy spotkania osobiste, podczas których zapytamy
                      Cię o Twoje doświadczenia oraz cele biznesowe. Im więcej
                      dowiemy się o Twojej firmie i Twoich oczekiwaniach, tym
                      lepiej dopasujemy idealnie rozwiązania, aby je spełnić
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_2">
                  <div className="inner_box">
                    <span>2.</span>
                    <h3>DOBIERAMY STRATEGIĘ</h3>
                    <p>
                      W marketingu internetowym nie ma uniwersalnych rozwiązań.
                      To, co sprawdza się w przypadku sklepów internetowych,
                      niekoniecznie sprawdzi się w przypadku promocji biznesowej
                      konferencji. Na podstawie naszego wieloletniego
                      doświadczenia dopasowujemy konkretne kanały promocji i ich
                      optymalne zastosowanie, które sprawdzi się w przypadku
                      Twojego biznesu.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_3">
                  <div className="inner_box">
                    <span>3.</span>
                    <h3>TWORZYMY I OPTYMALIZUJEMY</h3>
                    <p>
                      Tworzymy kampanie zgodne z naszymi założeniami. Na
                      podstawie naszych ustaleń, dobieramy lokalizację oraz
                      grupę docelową, do której dotrzemy z naszym komunikatem.
                      Nie pozostawiamy jej jednak samej sobie – na bieżąco
                      optymalizujemy ją, aby z dnia na dzień przyniosła jak
                      największe efekty.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_4">
                  <div className="inner_box">
                    <span>4.</span>
                    <h3>SPEŁNIAMY ZAŁOŻONE CELE</h3>
                    <p>
                      Dokładnie raportujemy wykonane działania, analizujemy
                      spełnione cele i wyznaczamy kolejne.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Marketing;
